import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import './css/CardImagen.css';

function CardImagen (props) {
  return (
      <Card 
      className="cardimagen" 
      >
         <a href={`${props.link}`} target="_blank">
        <Card.Img variant="top" src={`${props.imagen}`} className="cardimg" />
        {/*
        <Card.Body>
         <Card.Title></Card.Title>
          <Card.Text>
            {props.text} 
          </Card.Text>   
         </Card.Body>
        */}
        <Card.Footer className="text-muted text-uppercase">{props.titulo}</Card.Footer>
        </a>
      </Card>

  );
}

export default CardImagen;