import './App.css';
import Contenedor from './components/Contenedor';
import Header from './components/Header';
import MiFooter from './components/MiFooter';

function App() {
  return (
    <div className="App">
      <Header />
      <Contenedor />
      <MiFooter />
    </div>
  );
}

export default App;
