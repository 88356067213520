import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import './css/MiFooter.css';

function MiFooter() {
    return (
      <>
        <Navbar bg="dark" variant="dark" fixed="bottom" className="miFooter">
          <Container>
              <p className="text-end about fw-lighter">E.E.T. Nº 1 "General Francisco Ramírez" - 2023 -  GDA</p>

          </Container>
        </Navbar>
      </>
    );
  }
  
export default MiFooter;