import { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Alert from 'react-bootstrap/Alert';

import CardImagen from './CardImagen.js';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Spinner from 'react-bootstrap/Spinner';


//import { listadoContenidos } from '../contenidos.js';

import './css/Contenedor.css';

//const site = require.context('http://dashboard.intranet.eet1parana.edu.ar', true);
//const siteFiles = require.context('../assets/files', true);

const site = "https://dashboard.intranet.eet1parana.edu.ar";
const contenidos = site + "/" + "contenidos.json";

const siteImagenes = require.context('./assets/images', true);

function Contenedor() {

    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        if (isLoading) {
            async function fetchData() {
                try {
                    const response = await fetch(`${contenidos}`);
                    if (response.ok) {
                        const data = await response.json();
                        setData(data);
                        setError(null);
                        setIsLoading(false);
                    } else {
                        setError("Hubo un inconveniente al obtener el listado de contenidos a mostrar.");
                    }
                } catch (error) {
                    setError("No se pudo realizar la solicitud para obtener el listado de contenidos a mostrar.");
                }
            }
            fetchData();

            /*fetch('http://electronica.intranet.eet1parana.edu.ar/contenidos.json')
            .then((response) => response.json())
            .then((data) => setData(data))
            */
        }
    }, [isLoading]);

    console.log(data);

    //const listadoContenidos = data;
    //const listarContenidos = () => listadoContenidos.map((num, index) => console.log(num));

    // Funcion para generar numeros aleatorios
    const getRandomInt = (max) => {
        return Math.floor(Math.random() * max);
    }

    if (isLoading) {
        return (
            <>
                <h2 className="primary">Cargando...<br /></h2>
                <Spinner animation="border" variant="primary" />
            </>

        )
    }

    if (error) {
        
        return (
            <>
                <h2 className="primary">{error}</h2>
                <Alert variant="danger">Hubo un inconveniente al recibir la información a mostrar desde el servidor.</Alert>
            </>

        )
    }

    return (
        <Container className="text-center contenedor">
            <Row className="justify-content-md-center">


                        <Container className="text-center container-sm">
                            <Row>
                                <Alert variant="alert alert-success">
                                    <Alert.Heading>DASHBOARD</Alert.Heading>
                                    <p>
                                        Estos son los enlaces que a Usted se le permite acceder de forma libre.
                                    </p>
                                    <hr />
                                </Alert>
                                <p></p>
                                <p></p>
                                {data?.filter(contenido => contenido.panel == 1).map(contenido => (
                                    <Col lg={3} md={3} key={getRandomInt(100)}>
                                        <CardImagen
                                            key={getRandomInt(100)}

                                            titulo={contenido.titulo}
                                            text={contenido.descripcion}
                                            imagen={`${site}/images/${contenido.imagen}`}
                                            //imagen={`http://10.10.10.12:3000/assets/images/${contenido.imagen}`}
                                            //imagen={`${contenido.imagen}`}
                                            link={`${contenido.link}`}
                                            autor={contenido.autor}
                                            year={contenido.year}
                                        >
                                        </CardImagen>
                                    </Col>
                                ))}
                            </Row>
                        </Container>
    

            </Row>

        </Container>

    );
}

export default Contenedor;
