import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';

function Header() {
    return (
      <>
        <Navbar bg="dark" variant="dark" sticky="top" expand="lg">
          <Container>
            <Navbar.Brand href="#home">
              <h6>E.E.T. Nº 1 - Dashboar - Panel de Accesos</h6>
            </Navbar.Brand>
          </Container>
        </Navbar>
      </>
    );
  }
  
export default Header;