import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

const initialState = [
 {
    id: "9",
    curso: "1",
    titulo: "Fuente Lineal",
    file: "EsquemaFuente.pdf",
    imagen: "fuenteLineal.jpg",
    descripcion: "Esquema de una Fuente Lineal.",
    autor: "Fabián Herrera",
    year : "2023"
 }

];

export const contenidosSlice = createSlice({
    name: "contenidos",
    initialState,
    reducers: {
        addContenido: (state, action) => {
            const { id, curso, titulo, file, imagen, descripcion, autor, year } = action.payload;
            state.id = id;
            state.curso = curso;
            state.titulo = titulo;
            state.file = file;
            state.imagen = imagen;
            state.descripcion = descripcion;
            state.autor = autor;
            state.year = year;
        },
        changeFile: (state, action) => {
            state.file = action.payload;
        }

    }
});

export const { addContenido, changeFile } = contenidosSlice.actions;

export default contenidosSlice.reducer;